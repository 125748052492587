














import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Game } from '../model/game'

export default defineComponent({
  props: {
    game: {
      type: Object as PropType<Game.Game>,
      required: true,
    },
    winner: {
      type: String,
      required: true,
    },
  },

  setup(prop: {
    game: Game.Game;
    winner: string | null;
  }, context: SetupContext) {

    const cssclass = computed(() => {
      const you: any = { You: true };
      const opponent: any = { Opponent: true };
      if (!prop.winner) {
        you.turn = prop.game.player === "You";
        opponent.turn = prop.game.player === "Opponent";
      } else if (prop.winner === "You") {
        opponent.lose = true;
      } else if (prop.winner === "Opponent") {
        you.lose = true;
      }
      return {
        you,
        opponent,
      };
    });
    return {
      cssclass,
    };
  }
});
