
























import _ from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import useRouter from 'vue-router'
import { Game } from '../model/game'
import { M4Player } from '../model/player'
import { M4Match } from '../model/match'
import GameView from './Game.vue'
import Router from '../router/index'

export default defineComponent({
  components: {
    GameView,
  },

  setup(prop: {
  }, context: SetupContext) {
    
    const viewData: {
      games: Game.Game[];
    } = reactive({
      games: [],
    });

    M4Match.listGames(games => {
      viewData.games.splice(0, viewData.games.length, ...games);
    });
    return {
      viewData,
      handlers: {
        clickBack: () => Router.push('/'),
        clickItem: (game: Game.Game) => {
          Router.push(`/w/${game.match_id}`);
        },
      },
    };
  },
});
