























































import _, { random } from 'lodash';
import { reactive, ref, Ref, SetupContext, defineComponent, onMounted, PropType, watch, computed } from '@vue/composition-api';
import { Game } from '../model/game'
import { M4Player } from '../model/player'
import { M4Match } from '../model/match'
import GameView from '../views/Game.vue'
import Router from '../router/index'

type MatchingState = "None" | "Matching" | "Matched";

export default defineComponent({
  components: {
    GameView,
  },

  setup(prop: {
  }, context: SetupContext) {
    
    const player: M4Player.PlayerData = M4Player.publishPlayer();
    const viewData: {
      state: MatchingState;
      nickname: string;
      game: Game.Game | null;
      matchingError: string;
      openedMatches: number;
    } = reactive({
      state: "None",
      nickname: localStorage.getItem("mmmm_nickname") || "",
      game: null,
      matchingError: "",
      openedMatches: 0,
    });

    M4Match.watchMatch(player, (n) => {
      console.log(n);
      viewData.openedMatches = n;
    }).then(console.log, console.error);

    const effectiveNickname = computed(() => {
      const n = viewData.nickname.trim();
      if (!n) { return null; }
      if (n.length < 2) { return null; }
      return n;
    });

    const controllers = {
      startMatching: async () => {
        if (viewData.state !== "None") { return }
        viewData.state = "Matching";
        viewData.matchingError = "";
        try {
          player.name = effectiveNickname.value || "";
          localStorage.setItem("mmmm_nickname", player.name);
          const game = await M4Match.getMatch(player);
          viewData.game = reactive(game!) as Game.Game;
          viewData.state = "Matched";
        } catch (e) {
          console.log(e);
          viewData.state = "None";
          viewData.matchingError = "マッチングに失敗しました。もう一度お試しください。";
        }
      },
    };

    const handlers = {
      clickMatch: async () => {
        await controllers.startMatching();
      },

      matchAgain: async () => {
        viewData.game = null;
        viewData.state = "None";
      },

      clickWatcher: () => {
        Router.push('w');
      },

      clickSinglePlay: () => {
        if (effectiveNickname.value) {
          player.name = effectiveNickname.value || "";
          localStorage.setItem("mmmm_nickname", player.name);
        }
        const opponent = M4Player.publishCom();
        viewData.game = {
          match_id: "single-player",
          player_id_you: player.id,
          playerYou: player,
          player_id_opponent: opponent.id,
          playerOpponent: opponent,
          player: "You",
          neutral: false,
        }
        viewData.state = "Matched";
      },
    };
    return {
      player,
      viewData,
      effectiveNickname,
      handlers,
      mmmm: (() => {
        return ["0011", "0101", "1001", "0110", "1010", "1100"][Math.floor(Math.random() * 6)]
          .split("")
          .map(s => s === "0" ? "You" : "Opponent");
      })(),
    };
  },
});
